import Header from "./components/ header/Header";

import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "./components/carousel/Carousel";
import Footer from "./components/footer/Footer";
import Cardes from "./components/cards/Cardes";
import CardsDerails from "./components/cards/CardsDerails";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Login from "./pages/login/Login";

function App() {
  // const isAuthenticated = JSON.parse(localStorage.getItem("user"))?. emailId;
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Carousel />} />
          <Route path="/cardes" element={<Cardes />} />
          <Route path="/cart" element={<CardsDerails />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      {/* <Footer /> */}
    </>
  );
}

export default App;
