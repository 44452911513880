import React, { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { DLT } from "../../redux/actions/Action";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { TableRows } from "@mui/icons-material";

function CardsDerails() {
  const [data, setData] = useState([]);

  console.log(data);
  const { id } = useParams();
  const dispatch = useDispatch();
  const getdata = useSelector((state) => state.cartreducer.carts);
  useEffect(() => {
    setData(getdata);
  }, [getdata]);

  const dlt = (id) => {
    dispatch(DLT(id));
  };
  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, marginTop: "100px" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "green" }}>
              <TableCell>CardImg</TableCell>
              <TableCell align="right">Restaurant</TableCell>
              <TableCell align="right">price</TableCell>
              <TableCell align="right">Dishes</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Rating:</TableCell>

              <TableCell align="right">increment</TableCell>
              <TableCell align="right"> decrement </TableCell>
              <TableCell align="right">Delete data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((ele) => {
              return (
                <>
                  <TableRow>
                    <TableCell>
                      <img
                        src={ele.imgdata}
                        alt="img"
                        style={{
                          height: "20%",
                          maxHeight: "50%",
                          width: "10%",
                          marginLeft: "50px",
                          marginTop: "10px",
                        }}
                      ></img>
                    </TableCell>
                    <TableCell align="right">{ele.rname}</TableCell>
                    <TableCell align="right">{ele.price}</TableCell>
                    <TableCell align="right">{ele.address}</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right">Rating:{ele.rating} ✮</TableCell>

                    <TableCell align="right">
                      <Button variant="contained" sx={{ cursor: "pointer" }}>
                        {" "}
                        +
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained" sx={{ cursor: "pointer" }}>
                        {" "}
                        -
                      </Button>
                    </TableCell>
                    <TableCell align="right">
                      <DeleteIcon
                        onclick={dlt}
                        sx={{ color: "red", cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default CardsDerails;
