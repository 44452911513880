import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Cardsdata from "./CardeData";
import "./style.css";
import { useDispatch } from "react-redux";
import { ADD } from "../../redux/actions/Action";

function Cardes() {
  const [data, setData] = useState(Cardsdata);
  // console.log(data);

  const dispatch = useDispatch();

  const Send = (e) => {
    // console.log(e);
    dispatch(ADD(e));
  };

  return (
    <>
      <div className="container mt-3">
        <h2 className="text-center">Products</h2>

        <div className="row d-flex justify-content-center align-items-center">
          {data.map((element, id) => {
            return (
              <>
                <Card
                  style={{ width: "22rem", border: "none" }}
                  className="mx-2 mt-4 cars_style"
                >
                  <Card.Img
                    variant="top"
                    src={element.imgdata}
                    style={{ height: "16rem", marginLeft: "18px" }}
                    className="mt-3"
                  />
                  <Card.Body>
                    <Card.Title>{element.rname}</Card.Title>
                    <Card.Text>price: ₹ {element.price}</Card.Text>
                    <div className="button_div d-flex justify-content-center">
                      <Button
                        variant="primary"
                        onClick={() => Send(element)}
                        className="col-lg-12"
                      >
                        Add to card
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Cardes;
