import React from "react";
import image from "../../image/no-image.png";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import { useState } from "react";
import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DrawerComp from "./DrawerComp";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { DLT } from "../../redux/actions/Action";

// import { Carousel } from "bootstrap";

const PAGES = ["products", "Services", "ContactUs", "About Us"];

function Header() {
  const getdata = useSelector((state) => state.cartreducer.carts);
  console.log(getdata);

  const [value, setValue] = useState();
  const theme = useTheme();
  // console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(isMatch);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dlt = (id) => {
    dispatch(DLT(id));
  };
  const navigate = useNavigate();
  const nav = () => {
    navigate("/login");
  };
  return (
    <>
      <React.Fragment>
        <AppBar sx={{ background: "#063970" }}>
          <Toolbar>
            <Link to="/"sx={{  textdecoration:"none"}} >
              <Typography sx={{ fontSize:"2rem", color: "white" }}>
                ESHOP
              </Typography>
            </Link>
            {isMatch ? (
              <>
                <DrawerComp />
              </>
            ) : (
              <>
                <Tabs
                  sx={{ marginLeft: "auto" }}
                  textColor="inherit"
                  value={value}
                  onChange={(e, value) => setValue(value)}
                  indicatorColor="secondary "
                >
                  {PAGES.map((page, index) => (
                    <Tab key={index} label={page} />
                  ))}
                </Tabs>

                <Button
                  sx={{ marginLeft: "auto" }}
                  variant="contained"
                  onClick={nav}
                >
                  Login
                </Button>
                <Button sx={{ marginLeft: "10px" }} variant="contained">
                  Logout{}
                </Button>
              </>
            )}
            <Badge
              badgeContent={getdata.length}
              color="primary"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Link to="/cart">
                {" "}
                <ShoppingCartIcon sx={{ marginLeft: "20px", color: "white" }} />
              </Link>
            </Badge>
          </Toolbar>

          {/* <DeleteIcon onClick={() => dlt(e.id)} /> */}
        </AppBar>
      </React.Fragment>
    </>
  );
}

export default Header;
