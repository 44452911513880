import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
function Footer() {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#063970",
          color: "white",
          py: 5,
          overflow: "hidden",
          marginTop: "50px",
        }}
      >
        {/* Star Layer */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: "radial-gradient(white 1px, transparent 1px)",
            backgroundSize: "3px 3px",
            animation: "star-animation 50s linear infinite",
            zIndex: 0,
            opacity: 0.6,
          }}
        ></Box>

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 ,width:"76%"}}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ marginLeft: "100px" }}>
                <IconButton href="#" color="inherit">
                  <Facebook />
                </IconButton>
                <IconButton href="#" color="inherit">
                  <Twitter />
                </IconButton>
                <IconButton href="#" color="inherit">
                  <Instagram />
                </IconButton>
                <IconButton href="#" color="inherit">
                  <LinkedIn />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          {/* Footer Bottom Text */}
          <Box textAlign="center" pt={5}>
            <Typography variant="body2">
              © {new Date().getFullYear()} Your Company Name. All rights
              reserved.
            </Typography>
          </Box>
        </Container>

        {/* Animation CSS for Stars */}
        <style>
          {`
          @keyframes star-animation {
            0% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
            100% { transform: translateY(0); }
          }
        `}
        </style>
      </Box>
    </>
  );
}

export default Footer;
