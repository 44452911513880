import React, { useEffect, useRef, useState } from "react";
import "./Carousel.css";
import img from "../../image/data.json";
import Cardes from "../cards/Cardes";
function Carousel() {
  // console.log(img)
  const [next, setNext] = useState(0);
  let ref = useRef(null);
  const handleNext = () => {
    setNext((PreviousValue) => {
      if (PreviousValue === img.length - 1) {
        return 0;
      }
      return PreviousValue + 1;
    });
  };
  const handlepre = () => {
    if (next === 0) {
      setNext(img.length - 1);
    } else {
      setNext(next - 1);
    }
  };
  useEffect(() => {
    ref.current = setInterval(handleNext, 1000);
    return () => {
      clearInterval(ref.current);
    };
  }, []);
  return (
    <>
      <div
        className="container"
        onMouseEnter={() => clearInterval(ref.current)}
        onMouseLeave={() => (ref.current = setInterval(handleNext, 1000))}
      >
        <div className="left-btn">
          <button onClick={handlepre}>{"<"}</button>
        </div>
        <img src={img[next].download_url} alt="img"></img>
        <div className="right-btn">
          <button onClick={handleNext}>{">"}</button>
        </div>
      </div>
      <Cardes />
    </>
  );
}

export default Carousel;
